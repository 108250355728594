const firebaseConfig = {
	apiKey: "AIzaSyDvxkxt6QFrOladry7xJJV7rlyKKAbGmiE",
	authDomain: "deyal-1.firebaseapp.com",
	projectId: "deyal-1",
	storageBucket: "deyal-1.appspot.com",
	messagingSenderId: "596207480150",
	appId: "1:596207480150:web:9b3105276c64e258773af7",
};

export default firebaseConfig;
